
.rodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    overflow-y: auto;
    background: rgba(1, 1, 1, 0.5);

    .rodal-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
    }

    .rodal-dialog {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 101;
        margin: auto;
        width: 560px !important;
        padding: 72px;
        border-radius: 24px;
        height: -webkit-max-content !important;
        height: max-content !important;
        border-radius: 24px;
        background: linear-gradient(344deg, #E7E7E7 10.13%, #DCDFFF 87.89%);
        
        @media (max-width:992px) {
            padding: 30px;
            width: 50% !important;
            max-height: 600px!important;
        }

        @media (max-height: 750px) {
            padding: 25px;
        }

        @media (max-width:768px) {
            width: 80% !important;
        }

        @media (max-width:480px) {
            padding: 16px;
            width: 95% !important;
        }

        @media screen and (max-height: 600px) and (max-width: 992px) {
           height: 500px!important;
           margin-top: 20px;
        }
    }
}

.google-btn.signIn {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 32px;
    margin-bottom: 24px;
    border-bottom: 1px solid #B4B4B4;

    @media (max-width:992px),  (max-height: 750px) {
        padding-bottom: 16px;
        margin-bottom: 8px;
    }
}

