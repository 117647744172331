@import "../../../styles/variables";

.model-wrapper {
    width: 100%;
    text-align: left;

    .model-prompt-container {
        border-bottom: 1px solid #E8E8E8;

        .model-prompt-content {

            textarea {
                resize: vertical;
                width: 100%;
                height: 104px;
                border-radius: 4px;
                padding: 8px 16px;
                overflow: auto;
                border: 1px solid #E7E7E7;
                margin-bottom: 16px;
            }

            p {
                margin-bottom: 4px;
            }

            .prompt-output {
                margin-bottom: 26px;
                color: #878787;

                @media (max-width: 480px) {
                   font-size: 14px;
                   margin-bottom: 16px;
                }
            }
        }
    }

    .model-settings-content,
    .model-prompt-content {
        padding: 12px 24px;
    }

    .model-version-block {
        margin-bottom: 24px;

        @media (max-width: 480px) {
           margin-bottom: 16px;
        }

        p {
            margin-bottom: 4px;
        }

        .model-desc {
            color: #878787;
            @media (max-width: 480px) {
                font-size: 14px;
            }
        }

        .custom-select {
            width: 41%;
            margin-bottom: 4px;

            @media (max-width: 640px) {
                width: 100%;
            }
        }
    }

    .model-temperature-block {

        .temperature-range {
            display: flex;
            justify-content: space-between;

            span {
                color: $blue;;
                font-weight: 600;
            }
        }

        .temperature-info  {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;

        }

        .MuiSlider-root {
            width: calc(100% - 5px);
            height: 8px;
            margin-left: 5px;
            padding: 4px 0px 10px 0px;

        }

        .MuiSlider-track {
            border-radius: 4px;
            background: #6385FF;
            border: none;
        }

        .MuiSlider-rail {
            background: #D9D9D9;
        }

        .MuiSlider-thumb {
            box-shadow: none;
            background-color: #6385FF;
            width: 16px;
            height: 16px;
        }

        .MuiSlider-valueLabel {
            background-color: #6385FF;
            color: $main-color;
        }

        p {
            margin-bottom: 16px;
        }
    }
}