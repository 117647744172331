@import './styles/variables';
@import './styles/libs/normalize';

.App {
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $main-color;
  max-width: 1920px;
  overflow: hidden;

}



