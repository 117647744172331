@import '../../../styles/variables';

.custom-tabs {
  width: 226px;

  @media (max-width: 640px) {
    width: 100% !important;
    padding-bottom: 20px;

    ul {
      display: flex;
      flex-wrap: wrap;
      column-gap: 8px;
      justify-content: flex-start;
    }
  }

  @media (max-width: 480px) {
    ul {
      justify-content: center;

    }
  }

  ul {
    text-align: left;

    li {
      cursor: pointer;
      border-radius: 8px;
      padding: 4px 8px;
      margin-bottom: 4px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      position: relative;

      a {
        display: inline-block;
        width: 100%;
      }

      &:before {
        content: " ";
        display: none;
        position: absolute;
        left: 8px;
        top: 50%;
        width: 8px;
        height: 8px;
        transform: translateY(-50%);
        border-radius: 50%;
        background: #113DD8;
      }

      &::marker {
        color: #113DD8;
        width: 5px;

      }

      &:hover {
        background: #ECEFF3;
      }

      &.active {
        background: #ECEFF3;
        padding-left: 24px;

        a {
          color: #113DD8;
          font-weight: 600;
        }

        @media (max-width: 640px) {
          padding-left: 8px;
        }

        &:before {
          content: " ";
          display: unset;

          @media (max-width: 640px) {
            display: none;
          }
        }
      }
    }
  }

}