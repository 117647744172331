
.privacy-section {
    padding: 20px;
    text-wrap: wrap;
    text-align: left;
   
    h1 {
       text-align: center;
    }
   
    h1, p {
       margin-bottom: 20px;
    }
   
   }