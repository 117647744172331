@import '../../../styles/variables';

.question-contact {
    margin-bottom: 103px;
    padding: 0 24px;

    @media (max-width: 992px) {
       margin-bottom: 40px;
    }

    .question-contact-title {
        color: $main-color;
        margin-bottom: 24px;

        @media (max-width: 480px) {
            font-size: 20px;
        }
    }

    .custom-button {
        height: 59px;
        border-radius: 16px;
    } 

}