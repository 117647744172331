.lead-capture-wrapper {
    width: 100%;
    text-align: left;

    .lead-capture-content {
        padding: 32px 24px 7px;

        @media (max-width: 480px) {
            padding: 24px 16px 7px;
        }

        .custom-input {
            max-width: 74%;

            @media (max-width: 480px) {
                max-width: 100%;
            }
        }

        .lead-setting-block {
            display: flex;
            column-gap: 16px;
            margin-bottom: 20px;

            @media (max-width: 768px) {
                flex-direction: column;
                row-gap: 16px;
                margin-bottom: 16px;
            }

            .settings-block-btns {
                display: flex;
                column-gap: 16px;

            }
        }

        .title-block {
            display: flex;
            column-gap: 16px;
            margin-bottom: 32px;

            @media (max-width: 480px) {
               margin-bottom: 24px;;
            }
        }

        .checkbox-block {
            .custom-checkbox {
                margin-bottom: 24px;

                @media (max-width: 480px) {
                   margin-bottom: 16px;
                }
            }
            margin-bottom: 8px;
        }

        p {
            margin-bottom: 4px;
        }
    }
}