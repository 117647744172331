@import "../../styles/variables";

.my-chatbot-wrapper {
    width: 70%;
    margin: 0 auto 50px;
    text-align: center;

    .chatbot-title {
        color: $blue;
        font-size: 36px;
        font-weight: 700;
        margin-top: 36px;
        margin-bottom: 8px;
    }

    p {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 16px;
    }

    .my-chatbot-content {
        margin: 26px 0 0 0;

        .my-chatbot-list {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 16px;

            .new-chatbot-container {
                width: 180px;
                height: 224px;
                border-radius: 16px;
                background: #ECEFF3;
                cursor: pointer;


                .new-chatbot-content {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    padding: 16px;

                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: $blue;
                        font-size: 56px;
                        font-weight: 600;
                    }

                    p {
                        word-wrap: break-word;
                    }
                }
            }
        }
    }
}