
.upload-text-wrapper {

    .text-wrapper {
        width: 100%;
        padding: 10px;
        min-height: 300px;
        max-height: 666px;
        resize: none;
      }
    
    
}





