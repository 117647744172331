@import '../../../../styles/variables';

.faq-item {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    transition: all 0.3s linear;
    text-align: left;

    .item-answear{
        text-align: left;
    }

    @media (max-width: 992px) {
        padding: 0 8px 0 16px;

    }


    &:first-child {
        border-top: 1px solid #000;
    }

    &:hover:not(.active) {
        padding-left: 20px;
        background-color: #ECEFF3;
    }

    .item-header {
        width: 100%;
        height: 72px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            cursor: pointer;
            transition: all 0.4s linear;

            &.active {
                transform: rotateX(180deg);
            }
        }

        .question {
            color: black;
            font-style: 500px;
            font-size: 18px;
        }

    }

}