.general-wrapper {
    text-align: left;
    width: 100%;

    p {
        margin-bottom: 4px;
    }

    span {
        margin-right: 16px;
        font-weight: 600;
    }

    .general-info-container {
        border-bottom: 1px solid #E8E8E8;

    }

    .general-info-content {
        width: 100%;
        padding: 12px 24px;
        flex-direction: column;
        align-items: flex-start;

        .characters-block,
        .id-block {
            margin-bottom: 24px;
        }

        .id-block {

            .id-content {
                display: flex;
                align-items: center;

                img {
                    cursor: pointer;
                }
            }
        }

        .name-block {
            margin-bottom: 8px;

            .save-name-block {
                display: flex;
                flex-direction: row;

                @media (max-width: 480px) {
                    flex-direction: column;
                    row-gap: 16px;

                }

                .custom-input {
                    max-width: 57.4%;
                    margin-right: 16px;

                    @media (max-width: 480px) {
                        max-width: 90%;
                    }

                }
            }
        }
    }

    .last-trained-time {
        padding: 20px 24px;
    }
}