.chatbot-controls-wrapper {
    width: 1072px;
    min-height: 100vh;
    margin-bottom: 50px;

    @media (max-width:1280px) {
        width: 95%;
        max-width: 1072px;
    }

    h2 {

        @media (max-width:640px) {
            font-size: 30px;
        }

    }

    .chatbot-item-wrapper {
        border-radius: 8px;
        border: 0.5px solid rgba(0, 0, 0, 0.25);
        height: 629px;
        min-height: 300px;
    }


    .chatbot-menu-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow-x: scroll;
        position: relative;

        @media (max-width: 640px) {
            align-items: flex-start;
            border-bottom: 0.2px solid rgba(51, 51, 51, 0.5);
            padding-bottom: 8px;
            overflow-x: scroll;
            margin-bottom: 20px;

            .custom-menu {
                margin-bottom: 0;
                margin-right: 20px;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &::before {
            content: "";
            position: absolute;
            display: block;
            bottom: 68px;
            width: 100%;
            height: 0.2px;
            background-color: rgba(51, 51, 51, 0.5);

        }

        .chatbot-controls {
            display: flex;
            column-gap: 12px;
            padding: 0 8px;

            img {
                height: 23px;
                width: auto;
                cursor: pointer;
            }
        }
    }
}