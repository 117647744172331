@import '../../../styles/variables';

.custom-select {
    width: 100%;
    display: flex;
    padding: 8px 16px 8px 16px;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 4px;
    border: 1px solid #E7E7E7;
    min-height: 40px;
    font-family: Spline Sans;

}


select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../../resources/icons/Vector\ 1.png') no-repeat 97% 50%;
}

