@import '../variables';

* {
    padding: 0;
    margin: 0;
    list-style: none;
    outline: none;
    box-sizing: border-box;
    font-family: Spline Sans;
    font-size: 16px;
    line-height: 150%;
  }
  
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: $main-color;
  }

  button, input, ::placeholder {
    font-size: 16px;
  }
  
    
  a {
    text-decoration: none;
  }
  
  @mixin noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
    supported by Chrome, Opera and Firefox */
  }


  *::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #ECEFF3;
  }

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: white;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #D9D9D9;
  }

  h2 {
    margin-top: 32px;
    margin-bottom: 30px;
    color: $blue;
    font-size: 36px;
    font-weight: 700;
}

.title {
  color: $blue;
  font-size: 36px;
  font-weight: 700;

  
  @media (max-width: 1280px) {
   font-size: 30px;
  }

   @media (max-width: 450px) {
    font-size: 25px;
   }
}

