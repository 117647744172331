@import "../../../styles/variables";

.uploaded-list {
  margin: 10px 14px 14px 24px;

  ul {
    max-height: 350px;
    overflow-y: auto;
    text-align: left;
    position: relative;
    padding-right: 8px;
    scrollbar-gutter: stable;


    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      p {
        padding: 0;
        margin: 0;
        word-break: break-all;
        padding-right: 20px;
      }

      img {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }

      span {
        color: #878787;

        @media(max-width: 480px) {
          font-size: 14px;
        }
      }
    }
  }

  .list-title {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    color: $blue;
    text-align: center;
    font-weight: 600;
    position: relative;
    margin-bottom: 10px;

    @media (max-width: 480px) {
      flex-direction: column;
    }

    .delete-all {
      color: #9C0000;


      font-weight: 600;

    }

    .custom-button {
      position: absolute;
      right: 0;
      margin-right: 16px;

      @media (max-width: 480px) {
        position: static;
    }
  }

    span {
      color: #878787;
      font-weight: 400;
    }
  }

  .add-url-container {
    display: flex;
    justify-content: space-between;
    column-gap: 12px;
    align-items: center;
    margin-right: 14px;

    .delete-icon {
      cursor: pointer;
    }

    input {
      margin: 0 0 12px 0;
    }
  }

}