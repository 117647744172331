.qna-item {
    width: 100%;
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    background: #ECEFF3;
    margin-bottom: 4px;
    text-align: left;
    position: relative;

    .delete-qna {
        position: absolute;
        top: 8px;
        right: 16px;
        cursor: pointer;
    }

    .qna-question {
        font-weight: 600;
        margin-bottom: 4px;
        word-break: break-all;
        padding-right: 30px;
    }



    .qna-answer {
        width: 100%;
        word-break: break-all;
    }
}