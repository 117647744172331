@import '../../../styles/variables';

.footer-wrapper {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    background: #ECEFF3;

    @media (max-width: 992px) {}

    .footer-content {
        padding: 32px 64px;

        @media (max-width: 992px) {
            padding: 32px 36px;
        }

        @media (max-width: 992px) {
            padding: 16px 16px 24px 16px;
        }


        .footer-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 76px;
            margin-bottom: 56px;

            @media (max-width:768px) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: auto;
                margin-bottom: 24px;
                row-gap: 25px;
            }


            .footer-logo {
                margin-left: 24px;

                @media (max-width: 992px) {
                    margin-left: 0;
                }
            }

            .footer-links {
                display: flex;
                column-gap: 32px;

                @media (max-width: 992px) {
                    column-gap: 24px;
                }

                @media (max-width:768px) {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    row-gap: 17px;
                }



                a {

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -26px;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: $blue;
                        opacity: 0;
                        transform-origin: bottom right;
                        transition: all 0.5s linear;

                        @media (max-width: 768px) {
                            bottom: -5px;
                        }
                    }

                    &:hover,
                    &.active {
                        -webkit-text-stroke: 0.7px #333;

                        &::after {
                            opacity: 1;
                        }
                    }
                }

                li {
                    cursor: pointer;
                    position: relative;
                }
            }

            .footer-socials {
                display: flex;
                column-gap: 12px;
                align-items: center;

                img {
                    cursor: pointer;
                }
            }
        }

        .footer-bottom {
            border-top: 1px solid rgba(0, 0, 0, 0.25);
            display: flex;
            align-items: center;
            justify-content: center;
            height: 74px;
            margin-top: 32px;

            @media (max-width:768px) {
                margin-top: 0px;
                padding-top: 24px;
                height: auto;
                justify-content: flex-start;
            }

            .footer-about {
                display: flex;

                @media (max-width:768px) {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    row-gap: 16px;
                }

                p {
                    margin-right: 24px;
                }

                ul {
                    display: flex;
                    column-gap: 24px;

                    @media (max-width:768px) {
                        column-gap: 8px;
                        
                        a {
                            font-size: 14px;
                        }
                    }

                    li {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }

    }
}