.chatbot-item-wrapper {
    width: 100%;
    background: #FFF;
    height: 100vh;
    display: flex;
    flex-direction: column;

    &.close {
        display: none;
    }

    .chatbot-header {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
        position: relative;

        .chat-logo {
            height: 28px;
            margin-bottom: -5px;

        }

        .header-icons {
            position: absolute;
            right: 10px;
            top: 8px;
            display: flex;
            align-items: center;

            img {
                cursor: pointer;
                width: 22px;
                height: auto;
            }

            .close-icon {
                margin-left: 15px;
                width: 20px;
            }
        }

        h3 {
            text-align: center;
            font-family: Changa One;
            font-size: 20px;
        }
    }

    .chatbot-main-block {
        flex: 1 1;
        flex-basis: 0;
 

        .chatbot-chat-container {
            height: 100%;
        }

        .chatbot-chat-content {
            border-right: 0.5px solid rgba(0, 0, 0, 0.25);
            margin-right: 14px;
            display: flex;
            flex-direction: column;
            height: 100%; 

            .chatbot-dialog-block {
                overflow-y: auto;
                padding: 0 22px 11px 16px;
                margin-right: -11px;
                flex: 1 1;
                flex-basis: 0;
            }

            .chatbot-input-block {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 48px;
                margin: 10px 10px 8px 16px;
                border-radius: 2px;
                border: 0.5px solid rgba(0, 0, 0, 0.25);
                box-shadow: 0px 0px 6px 0px rgba(24, 68, 223, 0.15);

                input {
                    padding-left: 10px;
                    outline: none;
                    height: 36px;
                    border: none;
                    flex-grow: 1;
                }

                button {
                    border: none;
                    background-color: transparent;
                    margin-right: 10px;

                    img {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .message-left-block {
        text-align: left;
        padding: 0px 24px 9px;
        color: #878787;
        font-size: 12px;
    }
}
