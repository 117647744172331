@import '../../../styles/variables';

.live-chat-section {
    margin-bottom: 99px;
    width: 100%;

    @media (min-width: 1440px) {
        max-width: 880px;
    }

    @media (max-width: 1440px) {
        max-width: 880px;
    }

    @media (max-width: 1280px) {
        max-width: 68%;
    }

    @media (max-width: 992px) {
        max-width: 80%;
        margin-bottom: 45px;
    }

    @media (max-width: 480px) {
        margin-bottom: 31px;
        max-width: calc(100% - 14px);
    }

    .live-chat-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .live-chat-title {
            margin: 40px 0px 16px;

            @media (max-width: 480px) {
                margin: 0 0 8px 0;
                padding: 0 24px;
            }
        }

        .live-chat-subtitle {
            max-width: 712px;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 60px;

            @media (max-width: 480px) {
               margin-bottom: 16px;
               padding: 0 24px;
            }
        }

        .live-chat-block {
            width: 100%;
            .chatbot-item-wrapper {
                min-height: 600px;
                border-radius: 8px;
                border: 0.5px solid rgba(0, 0, 0, 0.25);

                .message-left-block {
                    display: none;
                }

                .chat-logo {
                    height: 24px;
                }
            }
        }

    }
}