
.upload-notion-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .notion-connect-container {
    min-height: 88px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
    
}





