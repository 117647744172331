.security-wrapper {
    width: 100%;
    text-align: left;

    p {
        margin-bottom: 4px;
    }

    .visibility-container {
        border-bottom: 1px solid #E8E8E8;

        .visibility-content {
            padding: 28px 24px 20px 24px;

            p {
                margin-bottom: 4px;
            }

            .custom-select {
                width: 42.3%;
                margin-bottom: 4px;

                @media (max-width: 640px) {
                    width: 100%;
                }
            }

            .visibility-output {
                color: #878787;

                @media (max-width: 640px) {
                    font-size: 14px;
                }
            }
        }
    }



    .rate-limit-content {
        padding: 20px 24px 28px 24px;

        .message-input-block {
            display: flex;
            column-gap: 16px;
        }

        .limit-inputs-block,
        .rate-limit-btns {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            column-gap: 16px;
        }

        .limit-inputs-block {
            width: 100%;
            margin-bottom: 32px;

            @media (max-width: 992px) {
                display: flex;
                flex-wrap: wrap;
                gap: 16px;

                .custom-input {
                    width: 100px;
                }
            }

        }

        .message-input-block {

            @media (max-width: 640px) {
                display: flex;
                flex-direction: column;
                row-gap: 16px;
            }

            .custom-input {
                max-width: 63.8%;

                @media (max-width: 640px) {
                    max-width: 100%;
                }
            }
        }

    }

}