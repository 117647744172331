@import '../../../styles/variables';

.features-section {
    margin-bottom: 138px;
    width: 100%;

    @media (min-width: 1440px) {
        max-width: 1280px;
    }

    @media (max-width: 1440px) {
        max-width: 1200px;
    }

    @media (max-width: 1280px) {
        max-width: 90%;
    }

    @media (max-width: 992px) {
        margin-bottom: 70px;
    }

    @media (max-width: 768px) {
        margin-bottom: 39px;
        max-width: calc(100% - 32px);
    }


    .features-content {
        width: 100%;

        .features-title {
            margin: 24px 0px 48px;

                
        @media (max-width: 992px) {
            margin: 24px 48px 8px 48px;
        }

        }

        .info-block {
            height: 683px;
            display: flex;
            column-gap: 24px;

            @media (max-width: 992px) {
                flex-direction: column;
                align-items: center;
                row-gap: 16px;
                height: auto;
            }

            .info-sidebar {
                width: 416px;
                height: 100%;
                border-radius: 24px;
                background: linear-gradient(344deg, #E7E7E7 10.13%, #DCDFFF 87.89%);


                @media (max-width: 992px) {
                    width: 100%;
                    background: none;
                }

                .info-mobile-desc {
                    display: none;
                    width: 80%;
                    margin: 0 auto;
                    font-size: 18px;

                    @media (max-width: 992px) {
                        display: block;
                    }

                    @media (max-width: 480px) {
                        font-size: 14px;
                        width: 100%;
                        padding: 0 8px;
                    }

                }


                .info-sidebar-content {
                    height: 100%;
                    padding: 24px 16px 24px 0px;
                    overflow: hidden;

                    @media (max-width: 992px) {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                        padding: 8px 0 16px;
                    }

                    .info-sidebar-item {
                        max-width: 90%;
                        margin-left: 24px;
                        margin-bottom: 28px;
                        text-align: left;
                        position: relative;
                        transition: all 0.3s linear;
                        cursor: pointer;


                        @media (max-width: 992px) {
                            background: linear-gradient(344deg, #E7E7E7 10.13%, #DCDFFF 87.89%);
                            border-radius: 16px;
                            padding: 8px;
                            margin-bottom: 0;
                            margin-left: 0;
                            max-width: auto;
                        }

                        &.active {
                            padding-left: 16px;

                            .item-label {
                                display: block;

                            }

                            .item-title {
                                color: $blue;
                            }

                            @media (max-width: 992px) {
                                padding-left: 8px;

                                .item-label {
                                    display: none;
                                }
                            }
                        }

                        .item-label {
                            display: none;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translate(-100%, -50%);
                            width: 51px;
                            height: 51px;
                            border-radius: 50%;
                            background: $blue;

                            @media (max-width: 992px) {
                                display: none;
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .item-title {
                            font-size: 24px;
                            font-weight: 600;

                            @media (max-width: 992px) {
                                font-size: 16px;
                            }


                            @media (max-width: 480px) {
                                font-size: 12px;
                            }
                        }

                        .item-text {
                            font-size: 18px;

                            @media (max-width: 992px) {
                                display: none;
                                font-size: 16px;
                            }

                        }
                    }
                }
            }

            .info-image {
                height: 100%;
                flex: 1 1 0;
                border-radius: 24px;
                background: #FFF;
                box-shadow: -15px 10px 50px 0px rgba(24, 68, 223, 0.20);

                @media (max-width: 992px) {
                    min-height: 400px;
                    width: 100%;
                }

                @media (max-width: 480px) {
                    min-height: 220px;
                    box-shadow: 0px 8px 16px 0px rgba(24, 68, 223, 0.20);
                }

            }
        }
    }
}