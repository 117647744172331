@import '../../../styles/variables';

.create-chatbot-container {
    margin-top: 10px;
    display: flex;
    padding: 16px 0px 16px 0px;
    justify-content: center;
    align-items: flex-end;
    gap: 32px;
    align-self: stretch;
    border-radius: 16px;
    background: #ECEFF3;

    @media (max-width:640px) {
        gap: 12px;
    }

    @media (max-width:480px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .chatbot-creation-info {
        display: flex;
        padding: 8px 0px 8px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1 0 0;
    }

    .create-btn {
        margin: 0px 16px;
        min-width: 160px;
    }

    .total-characters {
        color: $blue;
        font-weight: 600;
        padding: 0;
        margin: 0;
        text-align: left;

        span {
            color: #878787;
            font-weight: 400;
        }

    }

}