@import '../../../styles/variables';

.itegrates-section {
    margin-bottom: 58px;
    width: 100%;
    position: relative;
    padding: 0 20px;

    .integrate-title {
        margin-bottom: 53px;

        @media (max-width: 480px) {
            font-size: 20px;
            margin: 0 0 16px 0px;
        }
    }

    .animated-circles {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
        animation-name: circleAnimation;
        animation-duration: 4s; 
        animation-timing-function: ease; 
        animation-iteration-count: infinite; 
        width: 500px;
        height: 500px;

        @media (max-width: 992px) {
            width: 350px;
            height: 350px;
            right: 10%;;
        }

        @media (max-width: 768px) {
            display: none;
        }
        
        .animated-circle {
            border: 2px solid rgba(25, 66, 212, 0.2);
            border-radius: 50%;
            right: -240px;

            &.one {
                position: absolute;
                width: 72.6%;
                height: 72.6%;
                bottom: -20px;
            }
            
            &.two {
                position: absolute;
                width: 84%;
                height: 84.4%;
                bottom: -40px;
            }
            
            &.three {
                position: absolute;
                width: 96.4%; 
                height: 96.8%;
                bottom: -60px;
            }
            
            &.four {
                position: absolute;
                width: 107.6%; 
                height: 108.6%; 
                bottom: -80px;
            }
        }
    }

    .integrates-content {
        margin: 0 auto;
        width: 731px;

        @media (max-width: 768px) {
            width: 90%;
        }

        @media (max-width: 480px) {
            width: 100%;
        }

        .integrate-items {
            width: 100%;
            height: 416px;
            padding: 0 30px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;

            @media (max-width: 768px) {
                height: auto;
                justify-content: center;
            }

            @media (max-width: 480px) {
                padding: 0;
             }

            .integrate-item {
                width: 320px;
                height: 200px;
                border-radius: 20px;
                border: 1px solid #E8E8E8;
                box-shadow: 0px 4px 10px 0px rgba(24, 68, 223, 0.15);
                position: relative;
                transition: all 0.4s linear;
                background: white;
                cursor: pointer;

                @media (max-width: 768px) {
                   width: 60%;
                   min-width: 320px;

                   .item-icon {
                    align-self: center;
                   }
                }

            
                @media (max-width: 480px) {
                    width: 100%;
                   
                }


                &:hover {
                    transform: translateY(-5px);
                    background: #ECEFF3;

                    .arraw-right {
                        transform: rotate(-45deg);
                    }
                }

                &:nth-child(1) {
                    .item-icon {
                        margin: 16px 0px 60px 0px;
                        max-width: 211px;
                    }
                }

                &:nth-child(2) {
                    .item-icon {
                        margin: -16px 0px 0px 0px;
                        max-width: 194px;
                    }
                }

                &:nth-child(3) {
                    .item-icon {
                        margin: 11px 0px 28px 0px;
                        max-width: 231px;
                    }
                }

                &:nth-child(4) {
                    .item-icon {
                        margin: 13px 0px 30px 0px;
                        max-width: 177px;
                    }
                }

                .item-content {
                    padding: 16px 16px 16px 24px;
                    text-align: left;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;


                    .arraw-right {
                        position: absolute;
                        bottom: 30px;
                        right: 15px;
                        width: 20px;
                        height: 20px;
                        transition: all 0.4s linear;
                    }

                    .item-text {
                        max-width: 236px;
                        font-weight: 500;
                    }

                }
            }
        }
    }

    @keyframes circleAnimation {
        0% {
            transform: scale(1);
            opacity: 0.7;
        }
        50% {
            transform: scale(1.1);
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 0.7;
        }
      }
}