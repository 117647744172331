.custom-input {
    width: 100%;
    display: flex;
    padding: 8px 16px 8px 16px;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 4px;
    border: 1px solid #E7E7E7;
    min-height: 40px;
    font-family: Spline Sans;

}

