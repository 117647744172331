@import "../../styles/variables";

.header {
  width: 100%;
  padding: 10px 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $main-color;
  box-sizing: border-box;
  font-style: normal;
  position: relative;



  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    width: calc(100% - 128px);
    height: 0.2px;
    background-color: rgba(51, 51, 51, 0.5);

  }

  .header-menu {

    .mobile-menu-top {
      display: none;
    }


    ul {
      list-style-type: none;
      display: flex;
      gap: 32px;
      margin: 16px;
    }

    li {
      cursor: pointer;
      position: relative;
      color: $main-color;


      a {

        &::after {
          content: '';
          position: absolute;
          bottom: -26px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $blue;
          opacity: 0;
          transform-origin: bottom right;
          transition: all 0.5s linear;
        }

        &:hover,
        &.active {
          -webkit-text-stroke: 0.7px #333;

          &::after {
            opacity: 1;
          }
        }
      }


    }

  }


  .menu-icon {
    display: none;
    margin-right: 8px;
    cursor: pointer;
  }

  .login-register {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.mobile {
      margin: 20px 16px;
      display: none;
      width: fit-content;

      &::after {
        bottom: -10px;
      }

    }

    &::after {
      content: '';
      position: absolute;
      bottom: -26px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $blue;
      opacity: 0;
      transform-origin: bottom right;
      transition: all 0.5s linear;
    }


    &:hover,
    &.active {
      color: $blue;

      a {
        color: $blue;
      }

      &::after {
        opacity: 1;
      }


      img {
        filter: brightness(0) saturate(100%) invert(36%) sepia(97%) saturate(7305%) hue-rotate(231deg) brightness(89%) contrast(97%);
      }
    }

    span,
    a {
      font-weight: 700;
      text-decoration: none;
      cursor: pointer;
      margin-right: 8px;
      position: relative;
    }



    img {
      width: 13px;
      height: 12px;
    }
  }


}

.logo {
  margin-left: 24px;

}


@media (max-width: 992px) {
  .logo {
    margin-left: 0px;

    img {
      width: 200px;
      height: auto;
    }
  }
}

@media (max-width: 767px) {
  .header {
    padding: 22px 8px;

    .logo {
      margin-left: 16px;
    }

    &::before {
      width: calc(100% - 16px);
    }

    &.menu-opened {
      .header-menu {
        display: block;
        transform: translateX(0%);
      }

      .login-register .mobile {
        display: block;
      }

    }

    .header-menu {
      position: fixed;
      overflow: hidden;
      right: 0;
      top: 0;
      transform: translateX(100%);
      text-align: left;
      padding: 20px;
      width: 100%;
      border-radius: 5px 0px 0px 5px;
      background: linear-gradient(344deg, #E7E7E7 10.13%, #DCDFFF 87.89%);
      min-height: 100vh;
      z-index: 10;
      transition: all 0.3s ease-in-out;

      ul {
        gap: 24px;

        li {
          width: fit-content;
        }
      }

      a {
        &::after {
          bottom: -10px !important;
        }
      }

      .mobile-menu-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px;
        margin-bottom: 20px;
        position: relative;


        img {
          cursor: pointer;
        }

        &:before {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $main-color;
          transform-origin: bottom right;
          transition: all 0.5s linear;
        }
      }

      ul {
        flex-direction: column;
      }

    }

    .menu-icon {
      display: block;
    }

    .login-register {
      display: none;

      &.mobile {
        display: block;
      }
    }

  }
}