.chat-interface-wrapper {
    width: 100%;
    text-align: left;

    .intro-output,
    .suggested-output {
        margin-bottom: 26px;
        color: #878787;

        @media (max-width: 640px) {
            margin-bottom: 16px;
        }
    }

    .intro-btns-block, .suggested-bts-block {
       display: flex;
       flex-direction: row;
       column-gap: 16px;
       margin-bottom: 12px;
    }

    textarea {
        resize: vertical;
        width: 100%;
        height: 72px;
        border-radius: 4px;
        padding: 8px 16px;
        overflow: auto;
        border: 1px solid #E7E7E7;
        margin-bottom: 16px;
    }

    p {
        margin-bottom: 4px;
    }

    .chat-intro-container {
        border-bottom: 1px solid #E8E8E8;
    }

    .chat-intro-content,
    .suggested-message-content {
        padding: 12px 24px;
    }

    .model-version-block {
        margin-bottom: 24px;

        p {
            margin-bottom: 4px;
        }

        .model-desc {
            color: #878787;
        }

        .custom-input {
            width: 41%;
            margin-bottom: 4px;
        }
    }

}