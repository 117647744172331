@import "../../../styles/variables";


.contact-section {
    margin-bottom: 120px;
    padding: 0 24px;
    
    @media (max-width: 992px) {
        margin-bottom: 70px;
    }

    @media (max-width: 480px) {
        margin-bottom: 42px;
      }

    .contact-title {
        color: $main-color;
        margin-bottom: 16px;

        @media (max-width: 480px) {
          font-size: 20px;
          margin-bottom: 8px;
        }
    }

    .contact-subtitle {
        margin-bottom: 24px;

        @media (max-width: 480px) {
            font-size: 14px;
        }
    }

    .custom-button {
        height: 59px;
        border-radius: 16px;
    }
}