@import "../../../styles/variables";

.signIn-modal {

    h2 {
        font-size: 32px;
        margin: 0 0 48px 0;
        color: $main-color;

        @media (max-width:992px), (max-height: 750px) {
            margin: 16px 0 16px 0;
        }

        @media (max-width:480px) {
            font-size: 30px;
        }
    }

    h4 {
        font-size: 18px;
        margin: 0 0 48px 0;

        @media (max-width:992px),  (max-height: 750px) {
            margin: 0 0 16px 0;
        }
    }

    .close-icon {
        position: absolute;
        right: 24px;
        top: 24px;
        cursor: pointer;

        @media (max-width:480px) {
            width: 20px;
        }
    }


    .signIn-modal-form {
        text-align: left;

        .custom-input {
            height: 53px;
            margin-bottom: 24px;
            border-radius: 0px;
            border: 1px solid #878787;

            @media (max-width:992px),  (max-height: 750px) {
                margin-bottom: 16px;
                height: 40px;
            }
        }

        p {
            font-weight: 500;
            margin-bottom: 8px;
        }
    }

    .custom-button {
        margin: 8px 0px 32px;
        border-radius: 16px;
        height: 59px;

        @media (max-width:992px),  (max-height: 750px) {
            margin: 8px 0px 16px;
            height: 50px;
        }
    }


    .singIn-links {

        p {
            font-weight: 700;
            font-size: 14px;
            color: #1844DF;
            margin-bottom: 4px;
            cursor: pointer;
        }
    }
}

