@import '../../styles//variables';

.landing-wrapper {
    width: 100%;
    margin-top: 81px;
    height: auto;

    @media (max-width: 992px) {
        margin-top: 51px;
    }

    @media (max-width: 480px) {
        margin-top: 16px;
    }

    .landing-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}